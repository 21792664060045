<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Substância" v-model="localItem.nome_substancia" outlined :rules="requiredField" />
      </v-col>
       <v-col >
        <v-autocomplete dense label="Forma Farmacêutica" v-model="localItem.forma_farmaceutica" :items="forma_farmaceutica" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <v-col >
        <v-autocomplete dense :items="dietaOptions" label="Tipo de Dieta" v-model="localItem.dieta_enteral" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Item Padrão" v-model="localItem.padrao" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col >
        <v-autocomplete dense label="Via de Administração" :item-text="viaAdministracaoText" v-model="localItem.via_administracao" :items="via_administracao" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral" multiple chips >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
              {{ viaAdministracaoText(item) }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete dense label="Via Sonda" v-model="localItem.via_sonda" :items="via_sonda" item-text="nome" item-value="id" outlined :loading="isLoadingGeral" :rules="requiredField" return-object />
      </v-col>
    </v-row>
    <v-row >
       <v-col v-if="localItem?.via_sonda?.id === 1">
        <v-text-field dense label="Como devemos Administrar Via Sonda?" v-model="localItem.obs_via_sonda" outlined />
      </v-col>
    </v-row>
     <v-row dense>
      <v-col >
        <v-autocomplete dense label="Apresentação para armazenamento / distrubuição" :item-text="apresentacaoText" v-model="localItem.unidade_medida_estoque" :items="unidade_medida_estoque"  item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <v-col >
        <v-autocomplete dense label="Unidade de medida para prescrição" :item-text="unidadeText" v-model="localItem.apresentacao_prescricao" :items="apresentacao"  item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <v-col>
        <v-text-field dense label="Quantidade de unidade de medida da apresentação" v-model="localItem.qtd_unidade_medica_estoque" type="Number" outlined  :rules="requiredField"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Alto Risco" v-model="localItem.alto_risco" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Custo estimado " v-model="localItem.custo" outlined :options="options"/>
      </v-col>
      <v-col cols="3">
        <v-autocomplete dense :items="armazenamentoOptions" label="Temperatura Armazenamento" v-model="localItem.armazenamento" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
     <v-col >
        <v-autocomplete dense label="Endereço" v-model="localItem.enderecos" :items="endereco" :item-text="enderecoText" item-value="id" outlined :loading="isLoadingGeral"  :rules="requiredField"  multiple chips/>
      </v-col>
    </v-row>
    <v-row  v-if="localItem.alto_risco">
      <v-col>
        <v-text-field dense label="Qual o Risco para o Paciente?" v-model="localItem.qual_risco" outlined />
      </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <v-btn  dense style="color: red; font-weight: bold;" @click="limpar" class="mr-5">Limpar </v-btn>
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "NovaDieta",
  props: {
    currenItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      localItem: { ...this.currenItem },
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingGeral: false,
      apresentacao: [],
      via_administracao: [],
      forma_farmaceutica: [],
      via_sonda: [],
      unidade_medida_estoque: [],
      endereco: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
      dietaOptions: [
        { label: "Dieta Enteral", value: true },
        { label: "Dieta Parenteral", value: false }
      ],
      statusOptionsEspecial: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
        { label: "Não se Aplica", value: null }
      ],
       options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      armazenamentoOptions: [
        { label: "2ºC a 8ºC - Refrigerado", value: true },
        { label: "15ºC a 30ºC - Temperatura ambiente", value: false },
      ],
      
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)  return;

      // Validação para garantir que pelo menos uma via de administração seja selecionada
      if (!this.localItem.via_administracao || this.localItem.via_administracao.length === 0) {
        this.$toast.error('Por favor, selecione pelo menos uma Via de Administração.');
        return;
      }
      // Checagem manual para garantir que os campos obrigatórios estão preenchidos
      if (this.localItem.via_sonda?.id === 1 && !this.localItem.obs_via_sonda) {
        this.$toast.error('O campo "Como devemos Administrar Via Sonda?" é obrigatório.');
        return;
      }

      if (this.localItem.alto_risco === true && !this.localItem.qual_risco) {
        this.$toast.error('O campo "Qual o Risco para o Paciente?" é obrigatório.');
        return;
      }
      const fields = {
        ...this.localItem,
        via_sonda: this.localItem.via_sonda ? this.localItem.via_sonda.id : null,
        custo: this.localItem.custo ? this.localItem.custo : 0,
        classificacao: 11, // classificacao tipo Dieta
        tipo: 3, // tipo medicamento
      }
      // console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getApresentacao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/apresentacao/");
        this.apresentacao = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getViaAdministracao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/via_administracao/");
        this.via_administracao = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getFormafarmaceutica() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/forma_farmaceutica/");
        this.forma_farmaceutica = data
          .filter(item => item.ativo && [75,61,29,99].includes(item.id))
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getViaSonda() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/aplicavel/");
        this.via_sonda = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getUnidadeMedida() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/uteis/unidade/");
        this.unidade_medida_estoque = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getEndereco() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/enderecos/");
        this.endereco = data
          .filter(item => item.ativo)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    removeViaAdministracao(id) {
      this.localItem.via_administracao = this.localItem.via_administracao.filter(via => via !== id);
    },
    limpar() {
      this.localItem = {
        nome_substancia: "",
        dieta_enteral: null,
        padrao: null,
        alto_risco: null,
        via_sonda: null,
        custo: null,
        unidade_medida_estoque: null,
        apresentacao_prescricao: null,
        qtd_unidade_medica_estoque: null,
        via_administracao: [],
        classificacao: 11,
        tipo: 3,
        armazenamento : null,
        endereco: null,
      };

      this.$refs.formRef.resetValidation(); // Reseta validações
    },
    inicializar(){
      this.getApresentacao()
      this.getUnidadeMedida()
      this.getViaAdministracao()
      this.getFormafarmaceutica()
      this.getViaSonda()
      this.getEndereco()
    },
  },
  computed:{
    apresentacaoText() {
      return (item) => {
          return `${item.nome} - ${item.termo}`;
      }
    },
     enderecoText() {
      return (item) => {
          return `Rua: ${item.rua} - Prédio: ${item.predio} - APT: ${item.apt}`;
      }
    },
    unidadeText() {
      return (item) => {
          return `${item.nome} - ${item.abreviatura}`;
      }
    },
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    isViaSondaEnabled() {
      return this.localItem.via_sonda === true || this.localItem.via_sonda === "Sim";
    },
  },
  mounted() {
    this.inicializar()
  },
  watch: {
    "localItem.via_sonda"(newVal) {
      // Se via_sonda não for 1, limpa o campo obs_via_sonda
      if (!newVal || newVal.id !== 1) {
        this.localItem.obs_via_sonda = "";
      }
    },
    "localItem.alto_risco"(newVal) {
      if (!newVal) {
        this.localItem.qual_risco = "";
      }
    },
  },
}
</script>
